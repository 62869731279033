<template>
  <div v-if="$ability.can('obat.satuan.list')">
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Konversi Satuan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Obat / Konversi satuan</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          v-if="$ability.can('obat.satuan.create')"
          icon="pi pi-plus"
          label="Konversi Satuan"
          class="mr-2"
          @click="onAdd()"
        />
        <Button
          v-if="$ability.can('obat.satuan.export')"
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="onExport()"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
      >
        <template #columns>
          <Column
            field="kode"
            header="Kode"
            style="width: 250px"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.kode }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Kode"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="nama" header="Nama" :sortable="true">
            <template #body="{ data }">
              {{ data.nama }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Cari satuan"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="quantity"
            header="Qty"
            style="width: 80px; text-align: right"
          >
            <template #body="{ data }">
              {{ formatNumber(data.quantity) }}
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                v-if="
                  $ability.can('obat.satuan.update') ||
                  $ability.can('obat.satuan.view')
                "
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                v-if="$ability.can('obat.satuan.delete')"
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="
        form && form.id > 0 ? 'Edit Konversi Satuan' : 'Tambah Konversi Satuan'
      "
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-konversi-satuan
        :loading="isLoadingSave"
        :item="form"
        @save="onSave"
      />
    </Dialog>
    <Dialog
      header="Hapus Konversi Satuan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Konversi Satuan <strong>{{ form.nama }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          v-if="$ability.can('obat..satuan.delete')"
          label="Hapus"
          icon="pi pi-trash"
          @click="onDelete"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
  <div v-else>Anda tidak memiliki akses ke halaman ini.</div>
</template>

<script>
import errorHandler from '@/helpers/error-handler'
import FormKonversiSatuan from '@/components/master/FormKonversiSatuan'
import FxTable from '@/components/FxTable'
import KonversiSatuanService from '@/services/KonversiSatuanService'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatNumber } from '@/helpers'
import { ABILITY_TOKEN } from '@casl/vue'

export default {
  name: 'KonversiSatuan',
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  components: {
    FxTable,
    FormKonversiSatuan,
    Hotkey,
  },
  data() {
    return {
      konversiSatuanService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'kode',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.konversiSatuanService = new KonversiSatuanService()
  },
  mounted() {
    this.loadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.konversiSatuanService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Konversi Satuan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onAdd() {
      this.form = {
        id: 0,
      }
      this.dialog = true
    },
    onSave(data) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        // add new
        this.konversiSatuanService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Konversi Satuan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadGridData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Konversi Satuan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        // edit
        this.konversiSatuanService
          .update(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Konversi Satuan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadGridData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Konversi Satuan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDelete() {
      this.konversiSatuanService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Konversi Satuan',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Konversi Satuan', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAdd()
      }
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.konversiSatuanService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-satuan-obat.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Satuan Obat', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    formatNumber(value) {
      return formatNumber(value)
    },
  },
}
</script>
