<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label>NIK</label>
          <InputText v-model="form.nik" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
            >Nama *</label
          >
          <InputText
            v-model="form.nama"
            :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.nama.$invalid && submitted) ||
              v$.form.nama.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama.required.$message }}</small
          >
        </div>
        <div class="field col-12">
          <label>Alamat</label>
          <InputText v-model="form.alamat" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Kota</label>
          <InputText v-model="form.kota" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Area</label>
          <MultiSelect
            v-model="form.area"
            :options="areaSalesman"
            optionLabel="nama"
            placeholder="Pilih area"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>No. Telepon (1)</label>
          <InputText v-model="form.telepon_1" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>No. Telepon (2)</label>
          <InputText v-model="form.telepon_2" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>No. HP (1)</label>
          <InputText v-model="form.hp_1" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>No. HP (2)</label>
          <InputText v-model="form.hp_2" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Foto</label>
          <InputText
            type="file"
            class="w-full"
            accept="image/*"
            @change="previewImageFoto"
            maxlength="5000000"
          />
          <template v-if="previewFoto">
            <img
              :src="previewFoto"
              class="img-fluid p-mt-3"
              width="150"
              height="150"
            />
          </template>
        </div>
        <div class="field col-12 md:col-6">
          <label>KTP</label>
          <InputText
            type="file"
            class="w-full"
            accept="image/*"
            @change="previewimageKTP"
            maxlength="5000000"
          />
          <template v-if="previewKTP">
            <img
              :src="previewKTP"
              class="img-fluid p-mt-3"
              width="150"
              height="150"
            />
          </template>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    areaSalesman: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      previewFoto: this.item.foto,
      previewKTP: this.item.ktp,
      imageFoto: null,
      imageKTP: null,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0
        ? 'Tambah salesman'
        : 'Edit salesman'
    },
    form() {
      return this.item
    },
    area() {
      const area = []
      this.form.area.forEach((item) => {
        area.push(item.id)
      })
      return area
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      if (!isFormValid) {
        return
      }

      const result = Object.assign({}, this.form)
      result.area = this.area

      if (this.imageFoto && this.imageFoto.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file melebihi 5 MB',
          life: 3000,
        })
        return
      }

      if (this.imageKTP && this.imageKTP.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Ktp',
          detail: 'Ukuran file melebihi 5 MB',
          life: 3000,
        })
        return
      }

      this.submitted = true
      
      this.$emit('save', result, this.imageFoto, this.imageKTP)
    },
    previewImageFoto(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewFoto = e.target.result
        }
        this.image = input.files[0]
        this.imageFoto = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    previewimageKTP(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewKTP = e.target.result
        }
        this.image = input.files[0]
        this.imageKTP = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
      },
    }
  },
}
</script>
