<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-5">
          <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
            >Nama *</label
          >
          <InputText
            v-model="form.nama"
            :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.nama.$invalid && submitted) ||
              v$.form.nama.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-2">
          <label :class="{ 'p-error': v$.form.group.$invalid && submitted }"
            >Grup *</label
          >
          <Dropdown
            v-model="form.group"
            :filter="true"
            :options="groups"
            :class="{
                  'p-invalid': v$.form.group.$invalid && submitted,
                }"
            optionValue="id"
            optionLabel="nama"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.group.$invalid && submitted) ||
              v$.form.group.$pending.$response
            "
            class="p-error"
            >{{ v$.form.group.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-5">
          <label>Parent</label>
          <Dropdown
            v-model="form.parent_id"
            :filter="true"
            :options="parents"
            optionValue="id"
            optionLabel="nama"
            class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          v-if="
            $ability.can('obat.golongan.create') ||
            $ability.can('obat.golongan.update')
          "
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { ABILITY_TOKEN } from '@casl/vue'

export default {
  setup: () => ({ v$: useVuelidate() }),
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    groups: {
      type: Array,
      default() {
        return []
      },
    },
    parents: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0
        ? 'Tambah golongan obat'
        : 'Edit golongan obat'
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!this.form.parent_id) this.form.parent_id = 0

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage(
            'Nama golongan obat harus diisi.',
            required
          ),
        },
        group: {
          required: helpers.withMessage(
            'Grup harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>
