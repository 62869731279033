<template>
  <div>
    <DataTable
      :value="margin"
      responsiveLayout="scroll"
      showGridlines
      row-hover
      scrollable
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <ColumnGroup type="header">
        <Row>
          <Column header="Tanggal" :rowspan="3" />
          <Column header="Harga" :rowspan="3" />
          <Column header="Harga (sebelum)" :rowspan="3" />
          <Column header="Margin" :colspan="4" />
        </Row>
        <Row>
          <Column
            header="A"
            :colspan="2"
            class="text-center"
            style="text-align: center"
          />
          <Column header="B" :colspan="2" />
          <Column header="C" :colspan="2" />
          <Column header="D" :colspan="2" />
          <Column header="E" :colspan="2" />
          <Column header="F" :colspan="2" />
        </Row>
        <Row>
          <Column header="Sebelum" field="margin_bruto_A_prev" />
          <Column header="Sesudah" field="margin_bruto_A" />
          <Column header="Sebelum" field="margin_bruto_B_prev" />
          <Column header="Sesudah" field="margin_bruto_B" />
          <Column header="Sebelum" field="margin_bruto_C_prev" />
          <Column header="Sesudah" field="margin_bruto_C" />
          <Column header="Sebelum" field="margin_bruto_D_prev" />
          <Column header="Sesudah" field="margin_bruto_D" />
          <Column header="Sebelum" field="margin_bruto_E_prev" />
          <Column header="Sesudah" field="margin_bruto_E" />
          <Column header="Sebelum" field="margin_bruto_F_prev" />
          <Column header="Sesudah" field="margin_bruto_F" />
          <Column header="Perubahan" field="created_from" />
        </Row>
      </ColumnGroup>
      <Column field="created_at">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.created_at) }}
        </template>
      </Column>
      <Column field="harga_prev">
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.harga_prev) }}
        </template>
      </Column>
      <Column field="harga">
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.harga) }}
        </template>
      </Column>
      <Column field="margin_bruto_A_prev">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_A_prev) }}%
        </template>
      </Column>
      <Column field="margin_bruto_A">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_A) }}%
        </template>
      </Column>
      <Column field="margin_bruto_B_prev">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_B_prev) }}%
        </template>
      </Column>
      <Column field="margin_bruto_B">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_B) }}%
        </template>
      </Column>
      <Column field="margin_bruto_C_prev">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_C_prev) }}%
        </template>
      </Column>
      <Column field="margin_bruto_C">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_C) }}%
        </template>
      </Column>
      <Column field="margin_bruto_D_prev">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_D_prev) }}%
        </template>
      </Column>
      <Column field="margin_bruto_D">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_D) }}%
        </template>
      </Column>
      <Column field="margin_bruto_E_prev">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_E_prev) }}%
        </template>
      </Column>
      <Column field="margin_bruto_E">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_E) }}%
        </template>
      </Column>
      <Column field="margin_bruto_F_prev">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_F_prev) }}%
        </template>
      </Column>
      <Column field="margin_bruto_F">
        <template #body="slotProps">
          {{ formatNumber(slotProps.data.margin_bruto_F) }}%
        </template>
      </Column>
      <Column field="created_from">
        <template #body="slotProps">
          {{ slotProps.data.created_from }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { formatCurrency, formatNumber, formatDate } from '@/helpers'

export default {
  components: {
    ColumnGroup,
    Row,
  },
  props: {
    margin: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      value = value ? value : 0
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
