<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Salesman</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Salesman / Data salesman</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Salesman"
          class="mr-2"
          @click="onAdd"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="onExport()"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        :has-header="true"
        @request="onRequestData"
      >
        <template #header>
          <div style="text-align: left">
            <MultiSelect
              :modelValue="selectedColumns"
              :options="columns"
              optionLabel="header"
              @update:modelValue="onToggle"
              placeholder="Pilih kolom tambahan"
              style="width: 20em"
            />
          </div>
        </template>
        <template #columns>
          <Column
            field="kode"
            header="Kode"
            style="width: 170px"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.kode }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                placeholder="Kode"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="nama" header="Nama" :sortable="true">
            <template #body="{ data }">
              {{ data.nama }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Cari salesman"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="nik" header="NIK" style="width: 170px" />
          <Column field="alamat" header="Alamat" style="width: 250px" />
          <Column field="kota" header="Kota" />
          <Column
            v-for="(col, index) of selectedColumns"
            :field="col.field"
            :header="col.header"
            :key="col.field + '_' + index"
          />
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit salesman' : 'Tambah salesman'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-salesman
        :item="form"
        :loading="isLoadingSave"
        :area-salesman="area"
        @save="onSaveData"
      />
    </Dialog>
    <Dialog
      header="Hapus salesman"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Salesman <strong>{{ form.nama }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import AreaService from '@/services/AreaService'
import SalesmanService from '@/services/SalesmanService'
import FormSalesman from '@/components/master/FormSalesman'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import FxTable from '@/components/FxTable'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Salesman',
  components: {
    FormSalesman,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      salesmanService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      area: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      selectedColumns: null,
      columns: null,
    }
  },
  created() {
    this.salesmanService = new SalesmanService()
    this.columns = [
      { field: 'telepon_1', header: 'Telepon 1' },
      { field: 'telepon_2', header: 'Telepon 2' },
      { field: 'hp_1', header: 'HP 1' },
      { field: 'hp_2', header: 'HP 2' },
    ]
  },
  mounted() {
    this.loadData()
    const areaService = new AreaService()
    areaService
      .get()
      .then((res) => {
        const area = res.data.data
        area.forEach((el) => {
          this.area.push({ id: el.id, kode: el.kode, nama: el.nama })
        })
      })
      .catch((err) => {
        errorHandler(err, 'Data area', this)
      })
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.salesmanService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data salesman', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onAdd() {
      this.form = {
        id: 0,
        nik: '',
        nama: '',
        area: [],
        alamat: '',
        kota: '',
        telepon_1: '',
        telepon_2: '',
        hp_1: '',
        hp_2: '',
      }
      this.dialog = true
    },
    onSaveData(data, foto, ktp) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        // add
        this.salesmanService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.uploadImage(res.data.data, foto, ktp)
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data salesman', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        // edit
        const salesmanForm = Object.assign({}, data)

        delete salesmanForm.ktp
        delete salesmanForm.foto

        this.salesmanService
          .update(salesmanForm)
          .then((res) => {
            if (res.data.status === 200) {
              this.uploadImage(salesmanForm.id, foto, ktp)
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data salesman', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.salesmanService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data salesman',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data salesman', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    uploadImage(id_salesman, foto_file, ktp_file) {
      if (foto_file || ktp_file) {
        this.salesmanService
          .upload(id_salesman, foto_file, ktp_file)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data salesman',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Upload foto/KTP salesman', this)
          })
      } else {
        this.$toast.add({
          severity: 'success',
          summary: 'Data salesman',
          detail: 'Data berhasil disimpan.',
          life: 3000,
        })
        this.loadData()
        this.dialog = false
      }
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAdd()
      }
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.salesmanService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-salesman.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Salesman', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    },
  },
}
</script>
