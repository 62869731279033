<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.golongan_id.$invalid && submitted }"
            >Golongan obat *</label
          >
          <Dropdown
            v-model="form.golongan_id"
            :options="golonganObat"
            optionValue="id"
            optionLabel="nama"
            :class="{
              'p-invalid': v$.form.golongan_id.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.golongan_id.$invalid && submitted) ||
              v$.form.golongan_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.golongan_id.required.$message }}</small
          >
        </div>
        <div class="field col-12">
          <label :class="{ 'p-error': v$.form.komposisi.$invalid && submitted }"
            >Komposisi *</label
          >
          <InputText
            v-model="form.komposisi"
            :class="{ 'p-invalid': v$.form.komposisi.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.komposisi.$invalid && submitted) ||
              v$.form.komposisi.$pending.$response
            "
            class="p-error"
            >{{ v$.form.komposisi.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          v-if="
            $ability.can('obat.komposisi.create') ||
            $ability.can('obat.komposisi.update')
          "
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { ABILITY_TOKEN } from '@casl/vue'

export default {
  setup: () => ({ v$: useVuelidate() }),
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    golonganObat: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0
        ? 'Tambah komposisi obat'
        : 'Edit komposisi obat'
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        golongan_id: {
          required: helpers.withMessage('Golongan obat harus diisi.', required),
        },
        komposisi: {
          required: helpers.withMessage(
            'Komposisi obat harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>
