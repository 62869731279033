<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      filterDisplay="row"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      scrollable
      paginator
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @rowExpand="onRowExpand"
    >
      <template #header>
        <MultiSelect
          :modelValue="selectedColumns"
          :options="columns"
          optionLabel="header"
          placeholder="Pilih kolom tambahan"
          style="width: 15em"
          @update:modelValue="onToggle"
        />
      </template>
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="kode" header="Kode" style="min-width: 170px">
        <template #body="{ data }">
          {{ data.kode }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="nama"
        header="Nama"
        :sortable="true"
        style="min-width: 200px"
      >
        <template #body="{ data }">
          {{ data.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="stock_inventory"
        header="Stock Gudang"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatNumber(data.stock_inventory) }}
        </template>
      </Column>
      <Column
        field="stock_available"
        header="Stock Tersedia"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatNumber(data.stock_available) }}
        </template>
      </Column>
      <Column
        field="margin.harga"
        header="Harga Pokok"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga) }}
        </template>
      </Column>
      <Column
        field="margin.harga_jual_A"
        header="Harga A"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga_jual_A) }}
        </template>
      </Column>
      <Column
        field="margin.harga_jual_B"
        header="Harga B"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga_jual_B) }}
        </template>
      </Column>
      <Column
        field="margin.harga_jual_C"
        header="Harga C"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga_jual_C) }}
        </template>
      </Column>
      <Column
        field="margin.harga_jual_D"
        header="Harga D"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga_jual_D) }}
        </template>
      </Column>
      <Column
        field="margin.harga_jual_E"
        header="Harga E"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga_jual_E) }}
        </template>
      </Column>
      <Column
        field="margin.harga_jual_F"
        header="Harga F"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.margin.harga_jual_F) }}
        </template>
      </Column>
      <Column
        v-for="(col, index) of selectedColumns"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        style="min-width: 150px"
      />
      <Column sortable field="status" header="Status" style="min-width: 190px">
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['aktif', 'non-aktif']"
            :showClear="true"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        field="kategori_id"
        header="Kategori"
        style="min-width: 190px"
      >
        <template #body="{ data }">
          <div>
            {{ data.kategori.nama }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="kategoriObatList"
            optionValue="id"
            optionLabel="nama"
            :showClear="true"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column style="min-width: 110px">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-replay"
            class="mr-2 p-button-secondary p-button-text"
            v-tooltip.top="'Mutasi barang'"
            disabled
          />
          <Button
            v-if="$ability.can('obat.update') || $ability.can('obat.view')"
            type="button"
            icon="pi pi-pencil"
            class="mr-2 p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <Button
            v-if="$ability.can('obat.delete')"
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div v-if="slotProps.data.margin" class="grid mt-4">
          <div class="col-6">
            <div class="text-xl mb-4 font-bold">Margin</div>
            <div class="formgrid grid">
              <div class="field col-6">
                <label>Harga sebelum PPN</label>
                <InputNumber
                  v-model="slotProps.data.margin.harga"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  :minFractionDigits="2"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  disabled
                />
              </div>
              <div class="field col-6">
                <label>Harga Modal + PPN</label>
                <InputNumber
                  v-model="slotProps.data.margin.harga_ppn"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  :minFractionDigits="2"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  disabled
                />
              </div>
              <div class="field col-12">
                <DataTable
                  v-if="$ability.can('obat.margin.list')"
                  :value="slotProps.data.marginTable"
                  :scrollable="true"
                  showGridlines
                  scrollHeight="400px"
                >
                  <Column
                    field="margin"
                    header=""
                    style="flex-grow: 1; flex-basis: 30px"
                    frozen
                  />
                  <Column
                    field="margin_bruto"
                    header="Margin kotor"
                    style="
                      flex-grow: 1;
                      flex-basis: 100px;
                      justify-content: flex-end;
                    "
                  >
                    <template #body="{ data }">
                      {{ formatNumber(data.margin_bruto) }}
                    </template>
                  </Column>
                  <Column
                    field="margin_netto"
                    header="Margin bersih"
                    style="
                      flex-grow: 1;
                      flex-basis: 100px;
                      justify-content: flex-end;
                    "
                  >
                    <template #body="{ data }">
                      {{ formatNumber(data.margin_netto) }}
                    </template>
                  </Column>
                  <Column
                    field="margin_rp"
                    header="Margin Rp"
                    style="
                      flex-grow: 1;
                      flex-basis: 100px;
                      justify-content: flex-end;
                    "
                  >
                    <template #body="{ data }">
                      {{ formatNumber(data.margin_rp) }}
                    </template>
                  </Column>
                  <Column
                    field="harga_jual_ppn"
                    header="Harga Jual (PPN)"
                    style="
                      flex-grow: 1;
                      flex-basis: 200px;
                      justify-content: flex-end;
                    "
                  >
                    <template #body="{ data }">
                      {{ formatCurrency(data.harga_jual_ppn) }}
                    </template>
                  </Column>
                  <Column
                    field="harga_jual"
                    header="Harga Jual"
                    style="
                      flex-grow: 1;
                      flex-basis: 200px;
                      justify-content: flex-end;
                    "
                  >
                    <template #body="{ data }">
                      {{ formatCurrency(data.harga_jual) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="text-xl mb-4 font-bold">Stock</div>
            <DataTable
              v-if="$ability.can('obat.batch.list')"
              :value="slotProps.data.batch"
              class="mt-2"
              showGridlines
            >
              <Column
                header="No. Batch"
                field="batch_no"
                style="min-width: 100px"
              />
              <Column
                header="Exp. Date"
                field="expired_at"
                style="min-width: 100px"
              >
                <template #body="{ data }">
                  {{ formatDate(data.expired_at) }}
                </template>
              </Column>
              <Column
                header="Stock"
                field="quantity"
                style="min-width: 100px"
              />
              <Column
                header="Supplier"
                field="nama_supplier"
                style="min-width: 100px"
              />
            </DataTable>
            <div class="text-xl mb-4 mt-2 font-bold">Foto produk</div>
            <div v-if="slotProps.data.images.length > 0">
              <Image
                v-for="image in slotProps.data.images"
                :key="image"
                :src="image"
                alt="Image"
                width="200"
                class="mr-2"
                preview
              />
            </div>
            <div v-else>Belum ada foto</div>
          </div>
          <div class="col-12">
            <div class="text-xl mb-4 font-bold">RIWAYAT PERUBAHAN MARGIN</div>
            <grid-margin-history :margin="marginHistory" />
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import GridMarginHistory from '@/components/master/GridMarginHistory'
import { ABILITY_TOKEN } from '@casl/vue'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    kategoriObatList: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    marginHistory: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    GridMarginHistory,
  },
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  data() {
    return {
      margin_history: [],
      options: this.gridOptions,
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kategori_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      selectedColumns: null,
      columns: [
        { field: 'kode_ereport', header: 'E-Report' },
        { field: 'kode_siodie', header: 'SIODIE' },
        { field: 'kode_nie', header: 'NIE' },
        { field: 'nama_alias', header: 'Nama (alias)' },
        { field: 'status', header: 'Status' },
        { field: 'jenis_text', header: 'Jenis' },
        { field: 'golongan.nama', header: 'Golongan' },
        { field: 'komposisi.komposisi', header: 'Komposisi' },
        { field: 'satuan.nama', header: 'Satuan' },
        { field: 'satuan_tkcl.nama', header: 'Satuan Terkecil' },
        { field: 'stok_minimum', header: 'Stock Minimum' },
        { field: 'pabrik.nama', header: 'Pabrik' },
        { field: 'supplier.nama', header: 'Supplier' },
      ],
      expandedRows: [],
    }
  },
  methods: {
    onRowExpand(event) {
      let data = []
      data.push(event.data)
      this.expandedRows = data // single row
      this.$emit('marginhistory', event.data.id)
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.filters
      this.options.page = 1 // reset to page 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value, false)
    },
  },
}
</script>
